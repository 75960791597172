/* tslint:disable */

import zenObservable from 'zen-observable';

namespace Observable {

}

import { ZenObservable } from './types';

export { ZenObservable };

export type Observer<t> = ZenObservable.Người quan sát<t>;
Loại xuất khẩu Người đăng ký<t> = ZenObservable.Subscriber<t>;
loại xuất khẩu ObservableLike<t> = ZenObservable.ObservableLike<t>;

export const Observable: {
  new <t>(thuê bao: Người đăng ký<t>): Có thể quan sát được<t>;
  từ<r>(
    observable: Có thể quan sát được<r> | ZenObservable.ObservableLike<r> | MảngGiống như<r>,
  ): Có thể quan sát được<r>;
  của<r>(... args: Mảng<r>): Có thể quan sát được<r>;
} = <any>zenCó thể quan sát được;

giao diện xuất khẩu Có thể quan sát được<t> {
  subscribe(
    observerOrNext: ((value: T) => void) | ZenObservable.Observer<t>,
    lỗi?: (lỗi: bất kỳ) => void,
    complete?: () => void,
  ): ZenObservable.Subscription;

forEach(fn: (value: T) => void): Promise<void>;

bản đồ<r>(fn: (giá trị: T) => R): Có thể quan sát được<r>;

filter(fn: (value: T) => boolean): Có thể quan sát được<t>;

giảm<r =="" T="">(
    fn: (trướcGiá trị: R | T, currentValue: T) => R | T,
    initialValue?: R | T,
  ): Có thể quan sát được<r |="" T="">;

flatMap<r>(fn: (giá trị: T) => ZenObservable.ObservableLike<r>): Có thể quan sát được<r>;

từ<r>(
    observable: Có thể quan sát được<r> | ZenObservable.ObservableLike<r> | MảngGiống như<r>,
  ): Có thể quan sát được<r>;
  của<r>(... args: Mảng<r>): Có thể quan sát được<r>;
}
</r></r></r></r></r></r></r></r></r></r></r></r></r></t></r></r></void></t></t></any></r></r></r></r></r></r></r></r></t></t></t></t></t></t></t></t></t>